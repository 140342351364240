/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect, useState } from 'react';
import { useMaterialUIController } from 'context';

// next
import dynamic from 'next/dynamic';
import Image from 'next/image';

// Pro Components
import breakpoints from 'assets/theme/base/breakpoints';

// images
import bgPattern from 'assets/img/pattern-lines.svg';

// data
import { projects } from 'store/projects';

// utils
import stringToColor from 'utils/stringToColor';

// images
import codefox from 'assets/img/codefox.jpg';

// MUI
const AppBar = dynamic(() => import('@mui/material/AppBar'), { loading: () => null });
const Card = dynamic(() => import('@mui/material/Card'), { loading: () => null });
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });
const Tab = dynamic(() => import('@mui/material/Tab'), { loading: () => null });
const Tabs = dynamic(() => import('@mui/material/Tabs'), { loading: () => null });
const Tooltip = dynamic(() => import('@mui/material/Tooltip'), { loading: () => null });

// MUI Icons
const Monitor = dynamic(() => import('@mui/icons-material/Monitor'), { loading: () => null });
const OpenInNewRounded = dynamic(() => import('@mui/icons-material/OpenInNewRounded'), { loading: () => null });
const PhoneIphone = dynamic(() => import('@mui/icons-material/PhoneIphone'), { loading: () => null });

// PRO
const MDAvatar = dynamic(() => import('components/MDAvatar'), { loading: () => null });
const MDBadge = dynamic(() => import('components/MDBadge'), { loading: () => null });
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function Home() {
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const [activeTab, setActiveTab] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    window.addEventListener('resize', displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  return (
    <>
      <MDBox
        position="relative"
        variant="gradient"
        bgColor={darkMode ? 'dark' : 'secondary'}
        mt={{ xs: 0, lg: 12 }}
        mx={-2}
        sx={{ overflow: 'hidden' }}
      >
        <Image
          id="my-work"
          src={bgPattern.src}
          alt="background-pattern"
          fill
          style={{
            opacity: 0.6, top: 0, left: 0, transform: 'scale(2)',
          }}
        />
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MDBadge
              badgeContent="what I do"
              variant="gradient"
              container
              color={sidenavColor}
              sx={{ mb: 1 }}
            />
            <MDTypography variant="h3" color="white" mb={2}>
              My work
            </MDTypography>
            <MDTypography variant="body2" color="white" fontWeight="regular">
              Current highlighted projects.
            </MDTypography>
          </Grid>
        </Container>
      </MDBox>
      <MDBox mt={mobileView ? -8 : -16}>
        <Container>
          <Grid container sx={{ mb: 6 }}>
            <Grid item xs={7} md={6} lg={3} sx={{ mx: 'auto', textAlign: 'center' }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                  <Tab
                    id="desktop"
                    aria-label="desktop"
                    label={(
                      <MDBox py={0.5} px={2} color="inherit">
                        <Monitor />
                      </MDBox>
                    )}
                  />
                  <Tab
                    id="mobile"
                    aria-label="mobile"
                    label={(
                      <MDBox py={0.5} px={2} color="inherit">
                        <PhoneIphone />
                      </MDBox>
                    )}
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <MDBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3}>
              {projects.slice(0, 4).map((row) => (
                <Grid item xs={12} lg={6} xl={4} mb={{ xs: 3, lg: 3 }} key={`project-${row.id}`}>
                  <Card>
                    <MDBox position="relative" borderRadius="lg" mx={2} mt={-2} shadow="md">
                      <Image
                        width={activeTab === 0 ? 376 : 98}
                        height={212}
                        src={activeTab === 0 ? row.pics[0].url.src : row.phonePics[0].url.src}
                        alt={row.title}
                        style={{ borderRadius: 8, transform: 'scale(0.94)', filter: 'blur(12px)', position: "relative" }}
                        
                      />
                      <MDBox sx={{
                        display: 'block', position: 'relative', zIndex: 2, marginTop: '-224px',
                      }} shadow="md"
                      >
                        <Image
                          width={activeTab === 0 ? 376 : 98}
                          height={212}
                          src={activeTab === 0 ? row.pics[0].url.src
                            : row.phonePics[0].url.src}
                          alt={row.title}
                          style={{ borderRadius: 8, position: "relative" }}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.stack.length !== 0 && row.stack.map((item) => (
                          <MDTypography
                            key={`project-${row.id}-stack-${item}`}
                            variant="caption"
                            textTransform="uppercase"
                            fontWeight="medium"
                            mr={1.5}
                            mb={1}
                          >
                            <span style={{ color: `${stringToColor(item)}` }}>{item}</span>
                          </MDTypography>
                        ))}
                      </MDBox>
                      <MDBox display="flex" sx={{ alignItems: 'center' }}>
                        <MDTypography
                          variant="h5"
                          component="h3"
                          my={1}
                          sx={{ display: 'inline-block' }}
                        >
                          {row.title}
                        </MDTypography>
                        <Tooltip title="Open in new tab">
                          <span>
                            <MDButton
                              aria-label={`open ${row.url} in new`}
                              href={row.url}
                              target="_blank"
                              rel="noreferrer noopener"
                              iconOnly
                              sx={{ backgroundColor: 'initial !important', '&:focus': { boxShadow: 'none !important' } }}
                            >
                              <OpenInNewRounded color="info" sx={{ fontSize: '14px !important' }} />
                            </MDButton>
                          </span>
                        </Tooltip>
                      </MDBox>
                      <MDTypography variant="body2" component="p" color="text" sx={{ minHeight: 155 }} fontWeight="regular">
                        {row.description}
                      </MDTypography>
                      <MDBadge badgeContent={`${row.teamMembers} Team Members`} color="info" sx={{ ml: '-0.5rem' }} />
                      <MDBox display="flex" alignItems="center" mt={3}>
                        <MDAvatar
                          shadow="md"
                          variant="circular" // "rounded"
                        >
                          <Image src={codefox.src} alt="CodeFox" width="48" height="48" />
                        </MDAvatar>
                        <MDBox pl={2} lineHeight={0}>
                          <MDTypography variant="button" component="h3" fontWeight="medium" gutterBottom>
                            {row.developer}
                          </MDTypography>
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            {row.role}
                          </MDTypography>
                          <br />
                          <MDTypography variant="caption" color="text" fontWeight="regular">
                            Joined team in
                            {' '}
                            {row.joined}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
}

export default Home;
