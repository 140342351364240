/* eslint-disable no-bitwise */
import { useMaterialUIController } from 'context';

// convert a string to a color for avatar
export default function stringToColor(string) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  if (string === 'React') return '#479DC6';
  if (string === 'Next.JS') return '#479DC6';
  if (string === 'Express') return '#5FA04E';
  if (string === 'Socket.io') return darkMode ? '#afbbdb' : '#484d5b';
  if (string === 'Metamask') return '#F5841F';
  if (string === 'MailChimp') return '#E7B75F';
  if (string === 'S3') return '#f90';
  if (string === 'MUI') return '#4694e3'; // was #0072e5
  if (string === 'AdSense') return '#4C8BF5';
  if (string === 'D3') return '#F5844B';
  if (string === 'Ubuntu') return '#eb815b'; // was #e95420
  if (string === 'Ethereum') return '#7194f0'; // was #547FEF"
  if (string === 'Polygon') return '#a883e6'; // was "#8247E5"
  if (string === 'Stripe') return '#8b85ff'; // was #635BFF
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}
